.styled-box {
  color: red;
  border: 2px solid black;
}

.price {
  color: green;
  font-size: 30px;
  font-weight: bold;
}

@keyframes gradient {
  0% { background-position: 100% }
  100% { background-position: 0% }
}

.Demo__container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 30px);
  max-width: 400px;
  margin: 0 auto;
}

.Demo__some-network {
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  font-size: 14px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}
